<template>

    <section class="sectionAbout">
      <v-container class="conOfsection">
            <v-col cols="12" >
<div style="width: 100%">   <GmapMap
                      :center="center"
                      :options="mapStyle"
                      :zoom="zoom"
                      ref="map"
                      style="width: 100%; height: 400px">
                      <gmap-marker :position.sync="m.position" :key="m"  v-for="m in markers"></gmap-marker>
                       </GmapMap>
      
  </div>       
   </v-col>
      </v-container>
      <section class="formAndAddress">
        <div class="pattern"> </div>
     <v-container class="conOfsection">
        <v-row class="pos-relative" >
      
   
                  <v-col cols="12" >
    <v-row>

            <v-col md="4" cols="12">
                <div class="addressDiv">
                                       {{$t('Address')}}   

                       <p class="subTxt ">
                          {{ $t('worodAddress')}}
                    </p>
                </div>
            </v-col>
             <v-col md="4" cols="12">
              <div  v-bind:class="$i18n.locale === 'ar' ?' phonesDivAR' : 'phonesDiv'" >
                    {{$t('Phones')}}   
                      <p class="subTxt mt-0 mb-0">
                          +997-3763-3837638
                    </p>
                      <p class="subTxt mt-0 mb-0">
                          +997-3763-3837638
                    </p>
                      <p class="subTxt mt-0 mb-0">
                          +997-3763-3837638
                    </p>
                </div>
            </v-col>
             <v-col md="4" cols="12">
                    <div class="emailDiv">
        {{$t('pages.contact_us.Email')}}                    <p class="subTxt">
                        info@woroudLine.com
                    </p>
                </div>
            </v-col>

        </v-row>
                                </v-col>

          
        </v-row>
     </v-container>
     <section class="formSection">
       <v-container>
              <v-row>
             <v-col cols="12" class="pb-0 pt-0">
                       <div v-bind:class="$i18n.locale === 'ar' ?' boldTxtAr' : 'boldTxtEn'"  class="text-center">{{ $t('Have An Inquiry')}}</div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center align-center">
                       <div class="inquiryTxt text-center ">{{ $t('lorem-txt')}}
                    </div>
            </v-col>
              </v-row>
       </v-container>
      
         <v-container>
  <v-form v-bind:class="{'rtl':($i18n.locale === 'ar')}">

      <v-row>
        <v-col
         cols="12"
          sm="6"
         class="pb-0"

        >
        
           <v-text-field
             solo
          :label="$t('pages.contact_us.Name')"
           color="#360032"
                       background-color="#F9F9F9"
            v-model="name"
            :reverse="$i18n.locale === 'ar'"
            class="inputStyle"
          ></v-text-field>
          
        </v-col>

        <v-col
         cols="12"
          sm="6"
                  class=" pb-0"

        >
         <v-text-field
             solo
          :label="$t('pages.contact_us.Email')"
            v-model="email"
            color="#360032"
                        background-color="#F9F9F9"
            :reverse="$i18n.locale === 'ar'"

                        class="inputStyle"

          ></v-text-field>
        </v-col>

        <v-col
         cols="12"
          sm="6"
          class="pt-0 pb-0"

        >
            <v-text-field
             solo
          :label="$t('pages.contact_us.mobile')"
            v-model="mobile"
            color="#360032"
                        background-color="#F9F9F9"
            :reverse="$i18n.locale === 'ar'"

                        class="inputStyle"

          ></v-text-field>
        </v-col>

        <v-col
         cols="12"
          sm="6"
           class="pt-0 pb-0"

        >
          <v-text-field
             solo
          :label="$t('pages.contact_us.subject')"
            v-model="subject"
            color="#360032"
                        background-color="#F9F9F9"
            :reverse="$i18n.locale === 'ar'"

                        class="inputStyle"

          ></v-text-field>
        </v-col>

        <v-col
         cols="12"
          class="pt-0 pb-0"
        >
         <v-textarea
           solo
          name="input-7-4"
          :label="$t('pages.contact_us.Message')"
          v-model="message"
           color="#360032"
            background-color="#F9F9F9"
             class="inputStyle msgAr"
            :reverse="$i18n.locale === 'ar'"

        ></v-textarea>
        </v-col>
    <v-col cols="12" class="d-flex justify-center">
  <v-btn class="discover-btn" @click="sendMessage()" v-bind:class="{'ar-font':($i18n.locale === 'ar')}">
           {{ $t('pages.contact_us.submit')}}
          </v-btn>
     </v-col>
      </v-row>
  </v-form>
         </v-container>
      </section>
      </section>
    </section>

</template>
<script>
export default {
  name:'contact',
   data () {
    return {
        loader:false,
      info:[],
      name: '',
      email: '',
      subject:'',
      mobile:'',
      message:'',
        zoom: 10,
    mapStyle: {
        // other properties... 
        styles: [

            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "color": "#808080"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#b3b3b3"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "weight": 1.8
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#d7d7d7"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ebebeb"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#a7a7a7"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#efefef"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#696969"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#737373"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#d6d6d6"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {},
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            }
        ]
      },
    
     center: { lat:21.433333, lng: 40.35000000000002},
    markers: [{position:{lat:21.433333, lng: 40.35000000000002}}],
    }},
methods:{
    sendMessage(){
      this.$http.post('https://worod.cat-sw.com/api/v1/contact/store', {
        name:this.name,
        email:this.email,
        subject:this.subject,
        mobile:this.mobile,
        message:this.message
        }
        ).then(function(){
            this.$toasted.show('Your message was sent successfully',{ type : 'success',position :'bottom-center',duration:'3000'})
            
            }).catch(error => {
              this.$toasted.show('There was an error',{ type : 'error',position :'bottom-center',duration:'3000'})
      this.errorMessage = error;
      console.error('There was an error!', error);
    });
    }
  },
}

</script>
<style lang="scss" scoped>
.sectionAbout{
  display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.about-img{
    width: 100%;
}
.formSection{
  width: 100%;
  background: #FEF6FA;
  padding: 50px 0px;
}
.formAndAddress{
  width: 100%;
 position: relative;
}
.pos-relative{
  position: relative;
}
.conOfsection{
  margin-bottom: 50px;
}
.pattern{
      background: url('~@/assets/pattern.png');
    background-position: left;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    opacity: 0.5;
    height: 100%;
}
.addressDiv{
     
    font-size: 30px;
    background: url('~@/assets/address.png'), #9CCE6E;
    background-position: top;
    background-size: cover;
    height: 250px;
    color: #fff;
    padding: 20px;

}
.phonesDiv{
     
    font-size: 30px;
    padding: 20px;
    background: url('~@/assets/phone.png'), #F167A7;
    background-position: top;
    background-size: cover;
    height: 250px;
    color: #fff;

}
.phonesDivAR{
 
    font-size: 30px;
    padding: 20px;
    background: url('~@/assets/phone.png'), #F167A7;
    background-position: top;
    background-size: cover;
    height: 250px;
    color: #fff;

}
.emailDiv{
     
    font-size: 30px;
    background: url('~@/assets/email.png'), #79257B;
    background-position: top;
    background-size: cover;
    height: 250px;
    color: #fff;
    padding: 20px;

}
.inputStyle{
    border-radius: 0 !important;
}
.inquiryTxt{
        
color: #515251;

    font-size: 20px;
}
.subTxt{
       color: #ffff;

    font-size: 18px;
}
.discover-btn{
       text-transform: capitalize;

    letter-spacing: 1px;
    border-radius: 0;
   font-size: 0.9rem;
   background: #9CCE6E !important;
    color: #FFFFFF;
    height: 42px !important;
    width: fit-content;
    min-width: 64px;
  
    padding: 0px 30px !important;
    
  }
  .label.v-label.theme--light{
    right: 0 !important;
    left: unset !important;
  }
  ::v-deep{
 
    .v-input__slot {
      background-color: #ffff !important;
      box-shadow: unset !important;

}
.theme--light.v-label {
     

    color: #BABABA !important;
}


  }
       html:lang(ar){

  }
</style>
